@import "src/styles/variables";

/* Mediaqueries */
@mixin from($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin to($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin fromTo($from, $to) {
  @media (min-width: $to) and (max-width: $from) {
    @content;
  }
}

@mixin fromHeight($size) {
  @media (max-height: $size) {
    @content;
  }
}

@mixin fontface($name, $file, $weight: normal, $style: normal) {
  $path: "./../assets/fonts/" + $name + "/" + $file;
  @font-face {
    font-family: "#{$name}";
    src: url($path + ".eot");
    src: url($path + ".eot?#iefix") format("embedded-opentype"),
      url($path + ".woff") format("woff"),
      url($path + ".ttf") format("truetype"),
      url($path + ".svg#" + $name) format("svg");
    font-weight: $weight;
    font-style: normal;
  }
}

/* Definition config text */
@mixin config_text($fontFamily, $fontSize, $color, $lineHeight: false) {
  @if $lineHeight {
    line-height: $lineHeight;
  }
  font-family: $fontFamily;
  line-height: $lineHeight;
  font-size: $fontSize;
  color: $color;
}

/* --------*/

@mixin media-width($media) {
  @if $media == s {
    @media (max-width: $breakpoint-s) {
      @content;
    }
  } @else if $media == xs {
    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $media == sm {
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $media == md {
    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $media == lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  }
}

@mixin media-general($min-max-width-height, $breakpoint) {
  @media ($min-max-width-height: $breakpoint) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}
