@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot');
  src:  url('./fonts/icomoon.eot?ifknx7#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?ifknx7') format('truetype'),
    url('./fonts/icomoon.woff?ifknx7') format('woff'),
    url('./fonts/icomoon.svg?ifknx7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-face-id:before {
  content: "\e900";
}
.icon-asterisk:before {
  content: "\2022";
}
.icon-adduser:before {
  content: "\e901";
}
.icon-d:before {
  content: "\e902";
}
.icon-accepted-card:before {
  content: "\e903";
}
.icon-access:before {
  content: "\e904";
}
.icon-addmoney:before {
  content: "\e905";
}
.icon-addtocar:before {
  content: "\e906";
}
.icon-add-doc:before {
  content: "\e907";
}
.icon-address-book:before {
  content: "\e908";
}
.icon-alarm:before {
  content: "\e909";
}
.icon-alert:before {
  content: "\e90a";
}
.icon-align-left-1:before {
  content: "\e90b";
}
.icon-align-left:before {
  content: "\e90c";
}
.icon-align-right:before {
  content: "\e90d";
}
.icon-align:before {
  content: "\e90e";
}
.icon-amount:before {
  content: "\e90f";
}
.icon-analytics:before {
  content: "\e910";
}
.icon-arrow-angle-double-down:before {
  content: "\e911";
}
.icon-arrow-angle-double-left:before {
  content: "\e912";
}
.icon-arrow-angle-double-right:before {
  content: "\e913";
}
.icon-arrow-angle-double-up:before {
  content: "\e914";
}
.icon-arrow-diagonal:before {
  content: "\e915";
}
.icon-arrow-down:before {
  content: "\e916";
}
.icon-arrow-left:before {
  content: "\e917";
}
.icon-arrow-long-down:before {
  content: "\e918";
}
.icon-arrow-long-left:before {
  content: "\e919";
}
.icon-arrow-long-right:before {
  content: "\e91a";
}
.icon-arrow-long-up:before {
  content: "\e91b";
}
.icon-arrow-right:before {
  content: "\e91c";
}
.icon-arrow-up:before {
  content: "\e91d";
}
.icon-arrow2-down:before {
  content: "\e91e";
}
.icon-arrow2-left:before {
  content: "\e91f";
}
.icon-arrow2-right:before {
  content: "\e920";
}
.icon-arrow2-up:before {
  content: "\e921";
}
.icon-assistant:before {
  content: "\e922";
}
.icon-assistence:before {
  content: "\e923";
}
.icon-ATM:before {
  content: "\e924";
}
.icon-bankcommitments:before {
  content: "\e925";
}
.icon-barcode-scan:before {
  content: "\e926";
}
.icon-barcode:before {
  content: "\e927";
}
.icon-basket:before {
  content: "\e928";
}
.icon-benefits:before {
  content: "\e929";
}
.icon-bicycle:before {
  content: "\e92a";
}
.icon-bill:before {
  content: "\e92b";
}
.icon-birthday-cake:before {
  content: "\e92c";
}
.icon-block:before {
  content: "\e92d";
}
.icon-book:before {
  content: "\e92e";
}
.icon-bookmark:before {
  content: "\e92f";
}
.icon-building:before {
  content: "\e930";
}
.icon-bus:before {
  content: "\e931";
}
.icon-calculator:before {
  content: "\e932";
}
.icon-calendar-check:before {
  content: "\e933";
}
.icon-calendar-day:before {
  content: "\e934";
}
.icon-calendar-exclamation:before {
  content: "\e935";
}
.icon-calendar-minus:before {
  content: "\e936";
}
.icon-calendar-plus:before {
  content: "\e937";
}
.icon-calendar-premium:before {
  content: "\e938";
}
.icon-calendar-times:before {
  content: "\e939";
}
.icon-calendar:before {
  content: "\e93a";
}
.icon-calendaredit:before {
  content: "\e93b";
}
.icon-call-help:before {
  content: "\e93c";
}
.icon-camera:before {
  content: "\e93d";
}
.icon-capital:before {
  content: "\e93e";
}
.icon-car-plate:before {
  content: "\e93f";
}
.icon-car:before {
  content: "\e940";
}
.icon-card:before {
  content: "\e941";
}
.icon-cards:before {
  content: "\e942";
}
.icon-carousel:before {
  content: "\e943";
}
.icon-cash-contribution:before {
  content: "\e944";
}
.icon-cash-payment:before {
  content: "\e945";
}
.icon-cash:before {
  content: "\e946";
}
.icon-channels:before {
  content: "\e947";
}
.icon-chart-lines:before {
  content: "\e948";
}
.icon-chart-pie:before {
  content: "\e949";
}
.icon-chat-comments:before {
  content: "\e94a";
}
.icon-chat-dots:before {
  content: "\e94b";
}
.icon-chat:before {
  content: "\e94c";
}
.icon-checks:before {
  content: "\e94d";
}
.icon-child:before {
  content: "\e94e";
}
.icon-cinema:before {
  content: "\e94f";
}
.icon-clavedinamica:before {
  content: "\e950";
}
.icon-cloud-download:before {
  content: "\e951";
}
.icon-cloud-upload:before {
  content: "\e952";
}
.icon-cloud:before {
  content: "\e953";
}
.icon-cloudy:before {
  content: "\e954";
}
.icon-coffee:before {
  content: "\e955";
}
.icon-coin:before {
  content: "\e956";
}
.icon-comment:before {
  content: "\e957";
}
.icon-config:before {
  content: "\e958";
}
.icon-construction:before {
  content: "\e959";
}
.icon-contact-center:before {
  content: "\e95a";
}
.icon-contactless:before {
  content: "\e95b";
}
.icon-countdown:before {
  content: "\e95c";
}
.icon-currency-exchange:before {
  content: "\e95d";
}
.icon-data:before {
  content: "\e95e";
}
.icon-daytoday:before {
  content: "\e95f";
}
.icon-desktop:before {
  content: "\e960";
}
.icon-directions:before {
  content: "\e961";
}
.icon-document-check:before {
  content: "\e962";
}
.icon-document-delivery:before {
  content: "\e963";
}
.icon-document-search:before {
  content: "\e964";
}
.icon-document:before {
  content: "\e965";
}
.icon-documents:before {
  content: "\e966";
}
.icon-door:before {
  content: "\e967";
}
.icon-download-document:before {
  content: "\e968";
}
.icon-download-file:before {
  content: "\e969";
}
.icon-download:before {
  content: "\e96a";
}
.icon-e-card:before {
  content: "\e96b";
}
.icon-e-prepago:before {
  content: "\e96c";
}
.icon-edit-documento:before {
  content: "\e96d";
}
.icon-edit:before {
  content: "\e96e";
}
.icon-education:before {
  content: "\e96f";
}
.icon-erase:before {
  content: "\e970";
}
.icon-error:before {
  content: "\e971";
}
.icon-exchange:before {
  content: "\e972";
}
.icon-expand:before {
  content: "\e973";
}
.icon-external-link:before {
  content: "\e974";
}
.icon-extinguisher:before {
  content: "\e975";
}
.icon-face-angry:before {
  content: "\e976";
}
.icon-face-grin-wink:before {
  content: "\e977";
}
.icon-face-grin-wink2:before {
  content: "\e978";
}
.icon-face-laugh:before {
  content: "\e979";
}
.icon-face-laughbeam:before {
  content: "\e97a";
}
.icon-face-meh:before {
  content: "\e97b";
}
.icon-face-sad:before {
  content: "\e97c";
}
.icon-face-smile:before {
  content: "\e97d";
}
.icon-facebook:before {
  content: "\e97e";
}
.icon-faceid:before {
  content: "\e97f";
}
.icon-file-invoice:before {
  content: "\e980";
}
.icon-filter:before {
  content: "\e981";
}
.icon-filters:before {
  content: "\e982";
}
.icon-finantial-growth-indicator:before {
  content: "\e983";
}
.icon-finantial:before {
  content: "\e984";
}
.icon-find-doc:before {
  content: "\e985";
}
.icon-fingerprint:before {
  content: "\e986";
}
.icon-fire:before {
  content: "\e987";
}
.icon-flag:before {
  content: "\e988";
}
.icon-flight-cancelled:before {
  content: "\e989";
}
.icon-flight-late:before {
  content: "\e98a";
}
.icon-flight:before {
  content: "\e98b";
}
.icon-flypass:before {
  content: "\e98c";
}
.icon-folder:before {
  content: "\e98d";
}
.icon-food-burger:before {
  content: "\e98e";
}
.icon-gift:before {
  content: "\e98f";
}
.icon-gps:before {
  content: "\e990";
}
.icon-grocery:before {
  content: "\e991";
}
.icon-hand-button:before {
  content: "\e992";
}
.icon-hand-four-fingers:before {
  content: "\e993";
}
.icon-hand-index:before {
  content: "\e994";
}
.icon-hand-three-fingers:before {
  content: "\e995";
}
.icon-hand-top:before {
  content: "\e996";
}
.icon-hand-two-fingers:before {
  content: "\e997";
}
.icon-hand:before {
  content: "\e998";
}
.icon-health:before {
  content: "\e999";
}
.icon-heart:before {
  content: "\e99a";
}
.icon-help:before {
  content: "\e99b";
}
.icon-holding-money:before {
  content: "\e99c";
}
.icon-home:before {
  content: "\e99d";
}
.icon-house:before {
  content: "\e99e";
}
.icon-id-number:before {
  content: "\e99f";
}
.icon-id:before {
  content: "\e9a0";
}
.icon-idea:before {
  content: "\e9a1";
}
.icon-image:before {
  content: "\e9a2";
}
.icon-inbox:before {
  content: "\e9a3";
}
.icon-info:before {
  content: "\e9a4";
}
.icon-insert-coin:before {
  content: "\e9a5";
}
.icon-instagram:before {
  content: "\e9a6";
}
.icon-insurance:before {
  content: "\e9a7";
}
.icon-international:before {
  content: "\e9a8";
}
.icon-investment:before {
  content: "\e9a9";
}
.icon-language:before {
  content: "\e9aa";
}
.icon-laptop:before {
  content: "\e9ab";
}
.icon-law:before {
  content: "\e9ac";
}
.icon-like:before {
  content: "\e9ad";
}
.icon-line-of-people:before {
  content: "\e9ae";
}
.icon-linkedin:before {
  content: "\e9af";
}
.icon-list:before {
  content: "\e9b0";
}
.icon-location:before {
  content: "\e9b1";
}
.icon-lock-open:before {
  content: "\e9b2";
}
.icon-lock:before {
  content: "\e9b3";
}
.icon-login:before {
  content: "\e9b4";
}
.icon-luggage:before {
  content: "\e9b5";
}
.icon-mail-open:before {
  content: "\e9b6";
}
.icon-mail:before {
  content: "\e9b7";
}
.icon-medal:before {
  content: "\e9b8";
}
.icon-medical-assistance:before {
  content: "\e9b9";
}
.icon-menu:before {
  content: "\e9ba";
}
.icon-minus:before {
  content: "\e9bb";
}
.icon-mixed-service:before {
  content: "\e9bc";
}
.icon-mobile-payment:before {
  content: "\e9bd";
}
.icon-modify:before {
  content: "\e9be";
}
.icon-money-exchange:before {
  content: "\e9bf";
}
.icon-month:before {
  content: "\e9c0";
}
.icon-motorcycle:before {
  content: "\e9c1";
}
.icon-movie:before {
  content: "\e9c2";
}
.icon-museum:before {
  content: "\e9c3";
}
.icon-news:before {
  content: "\e9c4";
}
.icon-no-card:before {
  content: "\e9c5";
}
.icon-no-people:before {
  content: "\e9c6";
}
.icon-no-smoking:before {
  content: "\e9c7";
}
.icon-no-view:before {
  content: "\e9c8";
}
.icon-notification:before {
  content: "\e9c9";
}
.icon-office:before {
  content: "\e9ca";
}
.icon-ok:before {
  content: "\e9cb";
}
.icon-package:before {
  content: "\e9cc";
}
.icon-pass:before {
  content: "\e9cd";
}
.icon-password:before {
  content: "\e9ce";
}
.icon-paywith-wallet:before {
  content: "\e9cf";
}
.icon-people:before {
  content: "\e9d0";
}
.icon-percentage:before {
  content: "\e9d1";
}
.icon-performance:before {
  content: "\e9d2";
}
.icon-pet:before {
  content: "\e9d3";
}
.icon-phone-contact:before {
  content: "\e9d4";
}
.icon-phone-money:before {
  content: "\e9d5";
}
.icon-play:before {
  content: "\e9d6";
}
.icon-plus:before {
  content: "\e9d7";
}
.icon-pocket:before {
  content: "\e9d8";
}
.icon-podcast:before {
  content: "\e9d9";
}
.icon-preferential:before {
  content: "\e9da";
}
.icon-premium:before {
  content: "\e9db";
}
.icon-print:before {
  content: "\e9dc";
}
.icon-productnumber:before {
  content: "\e9dd";
}
.icon-puntos-colombia:before {
  content: "\e9de";
}
.icon-QR-add:before {
  content: "\e9df";
}
.icon-QR-heart:before {
  content: "\e9e0";
}
.icon-QR-scan:before {
  content: "\e9e1";
}
.icon-QR-send:before {
  content: "\e9e2";
}
.icon-quit:before {
  content: "\e9e3";
}
.icon-receipt:before {
  content: "\e9e4";
}
.icon-refresh:before {
  content: "\e9e5";
}
.icon-remove:before {
  content: "\e9e6";
}
.icon-removing-card:before {
  content: "\e9e7";
}
.icon-restaurant:before {
  content: "\e9e8";
}
.icon-route:before {
  content: "\e9e9";
}
.icon-salary:before {
  content: "\e9ea";
}
.icon-save:before {
  content: "\e9eb";
}
.icon-savings:before {
  content: "\e9ec";
}
.icon-search-charts:before {
  content: "\e9ed";
}
.icon-search-money:before {
  content: "\e9ee";
}
.icon-search:before {
  content: "\e9ef";
}
.icon-secondary-menu-horizontal:before {
  content: "\e9f0";
}
.icon-secondary-menu:before {
  content: "\e9f1";
}
.icon-security:before {
  content: "\e9f2";
}
.icon-see-balance:before {
  content: "\e9f3";
}
.icon-sendmoney-from:before {
  content: "\e9f4";
}
.icon-sendmoney-to:before {
  content: "\e9f5";
}
.icon-sendmoney:before {
  content: "\e9f6";
}
.icon-send-money:before {
  content: "\e9f7";
}
.icon-settings:before {
  content: "\e9f8";
}
.icon-share-external-link:before {
  content: "\e9f9";
}
.icon-share:before {
  content: "\e9fa";
}
.icon-ship:before {
  content: "\e9fb";
}
.icon-shopping:before {
  content: "\e9fc";
}
.icon-shower:before {
  content: "\e9fd";
}
.icon-smart-watch:before {
  content: "\e9fe";
}
.icon-smartphone:before {
  content: "\e9ff";
}
.icon-sort:before {
  content: "\ea00";
}
.icon-sound-off:before {
  content: "\ea01";
}
.icon-sound-on:before {
  content: "\ea02";
}
.icon-spinner:before {
  content: "\ea03";
}
.icon-square:before {
  content: "\ea04";
}
.icon-stethoscope:before {
  content: "\ea05";
}
.icon-stock-exchange:before {
  content: "\ea06";
}
.icon-stocks:before {
  content: "\ea07";
}
.icon-store:before {
  content: "\ea08";
}
.icon-subway:before {
  content: "\ea09";
}
.icon-successful:before {
  content: "\ea0a";
}
.icon-sunny:before {
  content: "\ea0b";
}
.icon-swimming:before {
  content: "\ea0c";
}
.icon-tag-price:before {
  content: "\ea0d";
}
.icon-tag-sale:before {
  content: "\ea0e";
}
.icon-target:before {
  content: "\ea0f";
}
.icon-taxes:before {
  content: "\ea10";
}
.icon-temperature:before {
  content: "\ea11";
}
.icon-thumbs-down:before {
  content: "\ea12";
}
.icon-thumbs-up:before {
  content: "\ea13";
}
.icon-thumbtack:before {
  content: "\ea14";
}
.icon-time:before {
  content: "\ea15";
}
.icon-trophy:before {
  content: "\ea16";
}
.icon-turn:before {
  content: "\ea17";
}
.icon-twitter:before {
  content: "\ea18";
}
.icon-umbrella-beach:before {
  content: "\ea19";
}
.icon-undo-payment:before {
  content: "\ea1a";
}
.icon-unemployment:before {
  content: "\ea1b";
}
.icon-upload:before {
  content: "\ea1c";
}
.icon-user:before {
  content: "\ea1d";
}
.icon-value:before {
  content: "\ea1e";
}
.icon-variations:before {
  content: "\ea1f";
}
.icon-view:before {
  content: "\ea20";
}
.icon-vip:before {
  content: "\ea21";
}
.icon-walk:before {
  content: "\ea22";
}
.icon-wallet:before {
  content: "\ea23";
}
.icon-window-analytics:before {
  content: "\ea24";
}
.icon-withdraw-cash:before {
  content: "\ea25";
}
.icon-wrench:before {
  content: "\ea26";
}
.icon-youtube:before {
  content: "\ea27";
}
.icon-zoom-minus:before {
  content: "\ea28";
}
.icon-zoom-plus:before {
  content: "\ea29";
}
.icon-megaphone:before {
  content: "\ea2a";
}
.icon-logos-Bam-endoso-primario-positivo:before {
  content: "\ea2b";
}
.icon-babybottle:before {
  content: "\ea2c";
}
.icon-logos-Bam-endoso-secundario-positivo:before {
  content: "\ea2d";
}
.icon-table-settings:before {
  content: "\ea2e";
}
.icon-logos-Bam-primario-positivo:before {
  content: "\ea2f";
}
.icon-whatsapp-outline:before {
  content: "\ea30";
}
.icon-logos-Bam-secundario-positivo:before {
  content: "\ea31";
}
.icon-microphone:before {
  content: "\ea32";
}
.icon-logos-BancadeInversion-primario-positivo:before {
  content: "\ea33";
}
.icon-facebook-outline:before {
  content: "\ea34";
}
.icon-logos-BancadeInversion-secundario-positivo:before {
  content: "\ea35";
}
.icon-instagram-outline:before {
  content: "\ea36";
}
.icon-logos-BancoAgricola-endoso-primario-positivo:before {
  content: "\ea37";
}
.icon-linkedin-outline:before {
  content: "\ea38";
}
.icon-logos-BancoAgricola-endoso-secundario-positivo:before {
  content: "\ea39";
}
.icon-twitter-outline:before {
  content: "\ea3a";
}
.icon-logos-BancoAgricola-primario-positivo:before {
  content: "\ea3b";
}
.icon-youtube-outline:before {
  content: "\ea3c";
}
.icon-logos-BancoAgricola-secundario-positivo:before {
  content: "\ea3d";
}
.icon-marketplace:before {
  content: "\ea3e";
}
.icon-logos-Bancolombia-endoso-primario-positivo:before {
  content: "\ea3f";
}
.icon-pending-store:before {
  content: "\ea40";
}
.icon-logos-Bancolombia-endoso-secundario-positivo:before {
  content: "\ea41";
}
.icon-powercord:before {
  content: "\ea42";
}
.icon-logos-Bancolombia-filiales-cayman-primario-positivo:before {
  content: "\ea43";
}
.icon-PreparerUser:before {
  content: "\ea44";
}
.icon-logos-Bancolombia-filiales-cayman-secundario-positivo:before {
  content: "\ea45";
}
.icon-project:before {
  content: "\ea46";
}
.icon-positivo:before {
  content: "\ea47";
}
.icon-forward:before {
  content: "\ea48";
}
.icon-logos-Bancolombia-filiales-panama-secundario-positivo:before {
  content: "\ea49";
}
.icon-reply:before {
  content: "\ea4a";
}
.icon-logos-Bancolombia-filiales-puertorico-primario-positivo:before {
  content: "\ea4b";
}
.icon-token:before {
  content: "\ea4c";
}
.icon-logos-Bancolombia-filiales-puertorico-secundario-positivo:before {
  content: "\ea4d";
}
.icon-DirectUser:before {
  content: "\ea4e";
}
.icon-logos-Bancolombia-isotipo-negro:before {
  content: "\ea4f";
}
.icon-ApprovingUser:before {
  content: "\ea50";
}
.icon-logos-Bancolombia-primario-positivo:before {
  content: "\ea51";
}
.icon-Usercheck:before {
  content: "\ea52";
}
.icon-logos-Bancolombia-secundario-positivo:before {
  content: "\ea53";
}
.icon-usererror:before {
  content: "\ea54";
}
.icon-logos-Banitsmo-endoso-primario-positivo:before {
  content: "\ea55";
}
.icon-Mainusers:before {
  content: "\ea56";
}
.icon-logos-Banitsmo-endoso-secundario-positivo:before {
  content: "\ea57";
}
.icon-lend:before {
  content: "\ea58";
}
.icon-logos-Banitsmo-primario-positivo:before {
  content: "\ea59";
}
.icon-gasoline-tank:before {
  content: "\ea5a";
}
.icon-logos-Banitsmo-secundario-positivo:before {
  content: "\ea5b";
}
.icon-bank:before {
  content: "\ea5c";
}
.icon-logos-FundacionGrupoBancolombia-primario-positivo:before {
  content: "\ea5d";
}
.icon-attachment:before {
  content: "\ea5e";
}
.icon-logos-FundacionGrupoBancolombia-secundario-positivo:before {
  content: "\ea5f";
}
.icon-affinity:before {
  content: "\ea60";
}
.icon-logos-GrupoBancolombia-endoso-positivo:before {
  content: "\ea61";
}
.icon-add-store:before {
  content: "\ea62";
}
.icon-logos-GrupoBancolombia-primario-positivo:before {
  content: "\ea63";
}
.icon-add_square:before {
  content: "\ea64";
}
.icon-logos-GrupoBancolombia-secundario-positivo:before {
  content: "\ea65";
}
.icon-logos-vigilado:before {
  content: "\ea66";
}
.icon-achievement:before {
  content: "\ea67";
}
.icon-balance:before {
  content: "\ea68";
}
.icon-coding:before {
  content: "\ea69";
}
.icon-map:before {
  content: "\ea6a";
}
.icon-tabot:before {
  content: "\ea6b";
}
.icon-controller:before {
  content: "\ea6c";
}
.icon-myproducts:before {
  content: "\ea6d";
}
.icon-scooter:before {
  content: "\ea6e";
}
.icon-shield-user:before {
  content: "\ea6f";
}
.icon-send-message:before {
  content: "\ea70";
}
.icon-send:before {
  content: "\ea71";
}
.icon-reply-all:before {
  content: "\ea72";
}
.icon-euro:before {
  content: "\ea73";
}
.icon-Peso:before {
  content: "\ea74";
}
.icon-logos-GrupoBancolombia-Capital-positivo:before {
  content: "\ea75";
}
.icon-cat:before {
  content: "\ea76";
}
.icon-Design:before {
  content: "\ea77";
}
.icon-dog:before {
  content: "\ea78";
}
.icon-Figma:before {
  content: "\ea79";
}
.icon-Sufi:before {
  content: "\ea7a";
}
.icon-mail-safe:before {
  content: "\ea7b";
}
.icon-ID-key:before {
  content: "\ea7c";
}
.icon-ID-lock:before {
  content: "\ea7d";
}
.icon-laptop-chat:before {
  content: "\ea7e";
}
.icon-phone-chat:before {
  content: "\ea7f";
}
.icon-receipt-add:before {
  content: "\ea80";
}
.icon-receipt-edit:before {
  content: "\ea81";
}
.icon-receipt-search:before {
  content: "\ea82";
}
.icon-password-safe:before {
  content: "\ea83";
}
.icon-chat-safe:before {
  content: "\ea84";
}
.icon-add-bank:before {
  content: "\ea85";
}
.icon-Gestor-transaccional:before {
  content: "\ea86";
}
.icon-elderly:before {
  content: "\ea87";
}
.icon-accesibility:before {
  content: "\ea88";
}
.icon-arrow3-down:before {
  content: "\ea89";
}
.icon-arrow3-left:before {
  content: "\ea8a";
}
.icon-arrow3-right:before {
  content: "\ea8b";
}
.icon-arrow3-up:before {
  content: "\ea8c";
}
.icon-component:before {
  content: "\ea8d";
}
.icon-foundation:before {
  content: "\ea8e";
}
.icon-widget:before {
  content: "\ea8f";
}
.icon-facemask:before {
  content: "\ea90";
}
.icon-options-menu:before {
  content: "\ea91";
}
.icon-ico-medal-2:before {
  content: "\ea92";
}
