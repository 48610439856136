/** Colors **/
$primary-yellow: #fdda24;
$white: #ffffff;
$black: #000000;
$general: #2c2a29;
$general-2: #292929;
$background: #f9f9fa;
$background-gray1: #f4f4f4;
$background-gray2: #cccccc;
$step-color: #00c389;
$success-color: $step-color;
$error-color: #ff7f41;
$warning-color: $primary-yellow;
$info-color: #59cbe8;
$gray1: #c4c4c4;
$error-message: #e20201;
$credit-color: #03814a;
$debit-color: $error-message;
$frozen-separator-color: rgba(93, 93, 93, 0.104965);
$messages-gray: #2c2a29cc;

/** Mixins **/
$breakpoint-s: 320px;
$breakpoint-xs: 360px;
$breakpoint-sm: 767px;
$breakpoint-lg-b: 992px;
$breakpoint-md: 1023px;
$breakpoint-xl: 1200px;
$breakpoint-lg: 1360px;
$breakpoint-1420px: 1420px;
$breakpoint-1720px: 1720px;

$breakpoint-desktop: 1023px;
