@import "./assets/fonts/icomoon/style.css";
@import "./styles/mixins";

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "CIBFontSans";
  src: url("./assets/fonts/CIBFontSans/CIBFontSans-Light.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "CIBFontSans Bold";
  src: url("./assets/fonts/CIBFontSans/CIBFontSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "CIBFontSerif Bold";
  src: url("./assets/fonts/CIBFontSans/CIBFontSerif-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

.pi {
  font-family: "primeicons";
}

body {
  font-size: 16px;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
.title {
  font-family: "CIBFontSans Bold";
}

h3 {
  font-size: 1.125em;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body * {
  box-sizing: border-box;
}

.modalSpinner {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(9.51399px);
}

.p-button.btn {
  font-size: 20px !important;
  font-family: "CIBFontSans Bold", sans-serif !important;
  padding: 0.8rem 1rem;
}

.buttons-desktop {
  display: none;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  direction: rtl;
  position: relative;
  min-height: 1rem;
  @include media-desktop {
    display: flex;
  }
  .btn-finalizar {
    display: inline-block;
    width: auto;
    margin: 0;
  }
  button.p-button.btn {
    padding: .5rem 1rem;
    min-width: 9rem;
  }
  .cancel {
    text-decoration: underline;
    font-weight: 600;
    color: $general;
    position: absolute;
    left: 0;
    font-size:.875rem;
  }
}

.main {
  background: $white;
  padding: 2rem 1.3rem;
  @include media-desktop {
    padding: 1.5rem 3rem;
  }
  box-shadow: 0px 3px 12px rgba(44, 42, 41, 0.08);
}

// ADECUACIONES LIBRERIAS BAM
@include media-mobile {
  #notification {
    .new-notification-container {
      padding: 0;
      .icon-column {
        width: unset;
        .lib-notification--icon {
          height: 100%;
          border-radius: 0;
          padding: 0.5rem;
          i {
            font-size: 25px;
          }
        }
      }
      .lib-notification--content {
        padding: 0.5rem 1rem;
      }
    }
  }
}
@include media-desktop {
  #notification {
    .new-notification-container {
      display: inline-flex;
      align-items: center;
      width: unset;
      padding-right: 1.3rem;
      .lib-notification--content {
        width: unset;
        p {
          display: inline-block;
        }
      }
    }
  }
}

#modal-authorization,
#modal-error {
  .lib-content-modal-body {
    margin: 1rem;
    max-width: 37rem;
  }
  .x-icon {
    min-height: 1rem;
    i {
      display: none;
      @include media-desktop {
        display: block;
      }
    }
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    & > * {
      flex: 1 0 200px;
    }
    button {
      padding: 0.6rem 2rem;
      white-space: nowrap;
    }

    @include media-desktop {
      direction: rtl;
      & > * {
        flex: 0 0 6rem;
      }
    }
  }
  main {
    padding: .3rem;
    @include media-desktop {
      padding: 0 3rem 1.3rem;
    }
  }
}
